import React from 'react'
import PropTypes from 'prop-types'

const SizePicker = (props) => {
  const { width, height, tileWidth, tileHeight, handleWidthChange, handleHeightChange } = props
  return (
    <div className='size-picker'>
      <div className='size-picker__title'>Grösse</div>
      <div className='size-picker__sizes'>
        <label htmlFor='width'>
          <span className='size-input-label'>Breite</span>
          <input
            type='number'
            id='width'
            name='width'
            value={width}
            step={tileWidth}
            min='70'
            max='175'
            onChange={(e) => handleWidthChange(e.target.name, e.target.value)}
          />
          <span className='size-input-unit'>cm</span>
        </label>
        <label htmlFor='height'>
          <span className='size-input-label'>Länge</span>
          <input
            type='number'
            id='height'
            name='height'
            value={height}
            step={tileHeight}
            min='90'
            max='380'
            onChange={(e) => handleHeightChange(e.target.name, e.target.value)}
          />
          <span className='size-input-unit'>cm</span>
        </label>
      </div>
    </div>
  )
}

SizePicker.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tileWidth: PropTypes.number.isRequired,
  tileHeight: PropTypes.number.isRequired,
  handleWidthChange: PropTypes.func.isRequired,
  handleHeightChange: PropTypes.func.isRequired
}

export default SizePicker
