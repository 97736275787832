import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TileTop from './TileTop'
import TileBottom from './TileBottom'

const CarpetPreview = (props) => {
  const { width, height, tileWidth, tileHeight, pattern, colors, handleRowSelection } = props
  const [activeRow, setActiveRow] = useState('')

  const onRowSelection = (row) => {
    setActiveRow(row)
    handleRowSelection(row)
  }

  const columns = Math.floor(width / tileWidth)
  const rows = Math.floor(height / tileHeight)

  let rowNumber = 0
  let rowNumber = 0
  const createdCarpet = [...Array(rows)].map(() => {
    rowNumber += 1
    const topId = `${rowNumber}-top`
    const bottomId = `${rowNumber}-bottom`

    // Get colors from state
    const topColors = colors[`${rowNumber}-top`]
    let topColor1
    let topColor2

    const bottomColors = colors[`${rowNumber}-bottom`]
    let bottomColor1
    let bottomColor2

    if (topColors) {
      topColor1 = topColors.color1
      topColor2 = topColors.color2
    }

    if (bottomColors) {
      bottomColor1 = bottomColors.color1
      bottomColor2 = bottomColors.color2
    }

    return (
      <React.Fragment key={rowNumber}>
        <div
          id={`row-${rowNumber}-top`}
          className={activeRow === topId ? 'row row-top is-active' : 'row row-top'}
          role='button'
          tabIndex='-1'
          data-id={topId}
          onClick={(e) => onRowSelection(e.currentTarget.dataset.id)}
          onKeyDown={(e) => onRowSelection(e.currentTarget.dataset.id)}>
          {[...Array(columns)].map((e, i) => {
            const id = i
            return (
              <TileTop
                key={id}
                className='tile top'
                color1={topColor1}
                color2={topColor2}
                pattern={pattern}
              />
            )
          })}
          <span className='row-number row-number-left'>{rowNumber}</span>
          <span className='row-number row-number-right'>{rowNumber}</span>
        </div>
        <div
          id={`row-${rowNumber}-bottom`}
          className={activeRow === bottomId ? 'row row-bottom is-active' : 'row row-bottom'}
          role='button'
          tabIndex='-1'
          data-id={bottomId}
          onClick={(e) => onRowSelection(e.currentTarget.dataset.id)}
          onKeyDown={(e) => onRowSelection(e.currentTarget.dataset.id)}>
          {[...Array(columns)].map((e, i) => {
            const id = i
            return (
              <TileBottom
                key={id}
                className='tile bottom'
                color1={bottomColor1}
                color2={bottomColor2}
                pattern={pattern}
              />
            )
          })}
        </div>
      </React.Fragment>
    )
  })

  return createdCarpet
}

CarpetPreview.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tileWidth: PropTypes.number.isRequired,
  tileHeight: PropTypes.number.isRequired,
  pattern: PropTypes.string.isRequired,
  colors: PropTypes.object.isRequired,
  handleRowSelection: PropTypes.func.isRequired
}

export default CarpetPreview
